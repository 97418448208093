<template>
  <div>
    <van-tabbar v-model="activeName">
      <van-tabbar-item replace :to="this.$route.query.id !== '' ? '/?id=' + this.$route.query.id : ''" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace :to="this.$route.query.id !== '' ? '/progress/?id=' + this.$route.query.id : ''" icon="manager-o">进度</van-tabbar-item>
    </van-tabbar>
  </div>

  <!-- <div>
    <van-tabbar v-model="active2">
      <van-tabbar-item icon="home-o">标签</van-tabbar-item>
      <van-tabbar-item icon="search">标签</van-tabbar-item>
      <van-tabbar-item icon="friends-o">标签</van-tabbar-item>
      <van-tabbar-item icon="setting-o">标签</van-tabbar-item>
    </van-tabbar>
  </div> -->

</template>

<script>
import { Tabbar, TabbarItem } from 'vant'

export default {
  name: 'FooterBar',
  data() {
    return {
      activeName: Number(sessionStorage.getItem('active')) || 0 // Tabbar中默认选中项
      // active2: 0
    }
  },
  methods: {
    onChange(index) {
      // console.log(index)
      if (index === 0) {
        // this.$refs.tabRef1.style.color = 'red'
        console.log(this.$refs)
      }
    }
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  mounted() {
    // console.log('FooterBar组件的$route: ', this.$route)
    sessionStorage.setItem('active', 0)
  }
}
</script>
