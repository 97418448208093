<template>
  <div id="app">
    <router-view />
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import FooterBar from '@/components/FooterBar.vue'
// import { getMarginStandardAPI } from '@/api'
export default {
  name: 'App',
  // async created() {
  //   const { data: res } = await getMarginStandardAPI()
  //   console.log(res)
  // }
  components: {
    FooterBar
  }
}
</script>

<style lang="less">
* {
  // touch-action: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  // touch-action: none;
}

</style>
