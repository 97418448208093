import request from '@/utils/request'
// import axios from 'axios'

/**
 * 查询保证金调整标准 +0 +1 +2 +3
 * @returns 
 */
export const getMarginStandardAPI = () => {
  return request({
    url: '/margins/scale/list'
  })
}

/**
 * 客户经理发起申请的请求
 * @param {*} param0 {calendarValue: 日期, username: 客户姓名, useraccount: 资金账号, scaleValue: 申请比例(0,1,2), reason: 申请理由}
 * @returns 
 */
export const initApplicationAPI = ({ calendarValue, username, useraccount, scaleValue, reason, clientManager, userDept }) => {
  return request({
    url: '/margins/apply/add',
    method: 'POST',
    data: {
      calendarValue,
      username,
      useraccount,
      scaleValue,
      reason,
      clientManager,
      userDept
    }
  })
}

/**
 * 上传客户基本信息(除签名外)的请求
 * @param {*} param0 
 * @returns Promise
 */
export const clientSignAPI = ({ id, calendarValue, username, useraccount, scaleValue, reason }) => {
  return request({
    url: '/margins/apply/customer',
    method: 'POST',
    data: {
      id,
      calendarValue,
      username,
      useraccount,
      scaleValue,
      reason
    }
  })
}

/**
 * 根据id查询对应申请的当前步骤
 * @param {*} id 申请的id值
 * @returns Promise
 */
export const getApplicationById = (id) => {
  return request({
    url: '/margins/apply/id',
    params: {
      id
    }
  })
}

/**
 * 上传客户、客户经理、机构负责人的签名接口
 * @param {} param0 {type: '客户签名', sigImg: 签名的base64, id: 申请的对应id}
 * @returns Promise
 */
// export const uploadSigImgAPI = ({type, sigImg, id})=>{
//   return request({
//     url: 'margins/sign/add',
//     method: 'POST',
//     data: {
//       type,
//       sigImg,
//       id
//     }
//   })
// }
export const uploadSigImgAPI = (data) => {
  return request({
    url: 'margins/sign/upload',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  })
}

/**
 * 客户经理的签字确认，(在上传客户经理签名后)
 * @param {} id: 申请表的id, mechanism: 对应的机构负责人userid, userDept: 发起人的部门id
 * @returns 
 */
export const marketSignConfirm = ({ id, mechanism, userDept }) => {
  return request({
    url: 'margins/apply/manager',
    method: 'POST',
    data: {
      id,
      mechanism,
      userDept
    }
  })
}
// export const marketSignConfirm = ({id}) => {
//   return axios({
//     url: 'margins/apply/manager',
//     method: 'post',
//     data:{
//       id
//     }
//   })
// }

/**
 * 机构负责人的签字确认,(在上传机构负责人签名后)
 * @param {*} id: 申请表的id  operate: 运作管理部人员的姓名
 * @returns 
 */
export const mechanismSignConfirm = ({ id }) => {
  return request({
    url: 'margins/apply/mechanism',
    method: 'POST',
    data: {
      id
    }

  })
}

/**
 * 运作部审批同意
 * @param {*} param0 {id: 申请的id, operate：对应审批人user}
 * @returns 
 */
export const riskDepConfirmAPI = ({ id, operate }) => {
  return request({
    url: 'margins/apply/operation',
    method: 'POST',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
    data: {
      id,
      operate
    }
  })
}


/**
 * 运作部审批不同意，填写拒绝理由
 * @param {*} param0 {id, refuseReason}
 * @returns 
 */
export const riskDepRefuseAPI = ({ id, refuseReason, operate }) => {
  return request({
    url: 'margins/apply/refuse',
    method: 'POST',
    data: {
      id,
      refuseReason,
      operate
    }
  })
}

/**
 * 获取企业微信认证的code
 * @returns 
 */
export const getCodeAPI = (id) => {
  return request({
    url: 'margins/wx/code',
    params: {
      id
    }
  })
}

/**
 * 根据企业微信链接地址code发请求获取发起人，发起人部门成员列表 
 * @returns 
 */
export const getUserIdAPI = (code) => {
  return request({
    url: 'margins/wx/simple',
    params: {
      code
    }
  })
}

export const getWxUserID = (code) => {
  return request({
    url: 'margins/wx/id',
    params: {
      code
    }
  })
}

export const getUserIdAndCodeAPI = (code, id) => {
  return request({
    url: 'margins/wx/simple',
    params: {
      code,
      id
    }
  })
}

/**
 * 通过部门id获取部门负责人信息
 * @param {*} deptId 部门id
 * @returns 
 */
export const getDepartmentManagerAPI = (deptId) => {
  return request({
    url: 'margins/wx/department',
    params: {
      deptId
    }
  })
}
