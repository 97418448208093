<template>
  <div class="home-container">
    <!-- banner区域 -->
    <div class="banner">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" width="100%" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 保证金优惠标准弹出文本区域 -->
    <div class="content">
      <div class="readme">
        <van-cell is-link @click="showPopup_standard" title="保证金优惠标准">
        </van-cell>
        <van-popup v-model="showStandard">
          <van-cell-group>
            <van-cell title="10万≤期货权益≤50万" value="交易所+3" />
            <van-cell title="50万≤期货权益≤200万" value="交易所+2" />
            <van-cell title="200万≤期货权益" value="交易所+1" />
          </van-cell-group>
        </van-popup>
      </div>
    </div>

    <!-- 表单区域 -->
    <van-form @submit="onSubmit">
      <!-- 选择日期 -->
      <van-field
        readonly
        clickable
        name="calendarValue"
        :value="calendarValue"
        label="申请日期"
        placeholder="选择日期"
        @click="showCalendarFn"
        :rules="[
          {
            required: true,
            message: '日期格式有误',
            pattern:
              /^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$/,
          },
        ]"
        colon
      />
      <van-calendar
        v-model="showCalendar"
        @confirm="onConfirm"
        :show-confirm="false"
      />

      <!-- 客户姓名 -->
      <van-field
        ref="usernameRef"
        v-model="username"
        name="username"
        label="客户姓名"
        placeholder="请输入客户姓名"
        :rules="[
          {
            required: true,
            message: '姓名格式有误',
            pattern: /[\u4e00-\u9fa5]/,
          },
        ]"
        colon
      />

      <!-- 资金账号 -->
      <van-field
        ref="useraccountRef"
        v-model="useraccount"
        name="useraccount"
        label="资金账号"
        placeholder="请输入资金账号"
        :rules="[
          { required: true, message: '资金账号格式有误', pattern: /^\d{4,8}$/ },
        ]"
        colon
      />

      <!-- 申请保证金优惠对应比例 -->
      <van-field
        readonly
        clickable
        label="申请比例"
        name="scaleText"
        :value="scaleText"
        placeholder="选择申请比例"
        @click="showPickerFn"
        colon
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="marginsList"
          value-key="scaleContent"
          @cancel="showPicker = false"
          @confirm="onConfirmProp"
          :default-index="0"
        />
      </van-popup>

      <!-- 申请理由文本 -->
      <van-field
        ref="reasonRef"
        v-model="reason"
        name="reason"
        type="textarea"
        label="申请理由"
        placeholder="申请理由"
        :rules="[{ required: true, message: '请填写申请理由' }]"
        rows="4"
        autosize
        input-align="left"
        colon
        maxlength="300"
      />

      <!-- 客户签名区域 -->
      <van-popup
        v-model="showMarginCertificate"
        :style="{ height: '60%' }"
        get-container="body"
        position="center"
      >
        <van-image src="https://s1.ax1x.com/2023/05/15/p9gRyb8.png"></van-image>
      </van-popup>
      <van-cell is-link @click="showPopup_signature" v-show="applyId !== ''"
        >客户签名</van-cell
      >
      <van-popup
        v-model="showPopSignature"
        :style="{ height: '55%' }"
        get-container="body"
        position="top"
      >
        <div style="margin-top: 50px">
          <p
            style="
              padding: 0 20px 0 10px;
              text-indent: 2em;
              color: #333;
              line-height: 16px;
            "
          >
            本人深知期货市场的风险性，并将严格按照贵公司风险控制标准执行，现本人向贵公司提出特殊交易保证金优惠申请，如本人出现风险配合度差、资金持续不达标或贵公司认定的其他需要调整保证金比例的情况，贵公司有权根据各期货交易所、市场行情波动等情况，对本人期货账户保证金比例进行调整，并愿意承担由此造成的一切风险！
          </p>
        </div>
        <Signature @close="closeDialog"> </Signature>
      </van-popup>
      <van-cell
        v-show="applyId !== '' && applyCourse == 0"
        style="font-size: 12px; line-height: 12px; padding: 0 0.426rem"
      >
        <van-checkbox
          v-model="marginCertificateFlag"
          ref="marginCertificateFlagRef"
          style="font-size: 12px"
          shape="square"
          icon-size="12px"
        >
          <van-cell
            @click="showPopup_marginCertificate"
            ref="marginCertificateText"
            :value="marginCertificateValue"
            style="font-size: 12px; padding-left: 0"
          ></van-cell>
        </van-checkbox>
      </van-cell>
      <img
        :src="sigImg"
        alt=""
        v-show="true"
        style="width:100%; height=400px"
      />
      <div style="margin: 16px">
        <!-- 提交按钮 -->
        <van-button
          round
          block
          ref="submitBtnRef"
          type="info"
          native-type="submit"
          name="submit"
          class="submitBtn"
          :disabled="disableFlg"
          >{{ submitBtnText }}</van-button
        >
      </div>
    </van-form>

    <!-- 底部区域 -->
    <!-- <div class="footer"> -->
    <!-- 跑马灯提示区域 -->
    <van-notice-bar
      left-icon="volume-o"
      text="中财期货线上移动端保证金优惠申请......"
      scrollable
    />
    <!-- 固定尾部区域 -->
    <!-- <router-view></router-view>
    <FooterBar></FooterBar> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { baseURL } from "@/utils/request.js";
import Signature from "components/Signature.vue";

import {
  getMarginStandardAPI,
  getApplicationById,
  clientSignAPI,
  uploadSigImgAPI,
} from "@/api";
import {
  Checkbox,
  Swipe,
  SwipeItem,
  Popup,
  Button,
  Cell,
  CellGroup,
  Row,
  Col,
  Form,
  Field,
  Calendar,
  Picker,
  Dialog,
  NoticeBar,
  Toast,
  ShareSheet,
  Step,
  Steps,
  Notify,
  RadioGroup,
  Radio,
} from "vant";

export default {
  name: "ClientHome",
  data() {
    return {
      // 企业微信认证的code
      weComCode: "",
      // 企业微信的申请用户
      weComUserId: "",
      // 企业微信的申请用户对应部门成员列表
      weComDeptUserList: [],

      marginCertificateValue: "点击浏览《申请特殊交易保证金告知书》",
      // 是否显示《保证金告知书》弹出层的开关
      showMarginCertificate: false,
      // 是否已浏览《保证金告知书》的开关
      marginCertificateFlag: false,
      // 控制客户姓名输入框的只读状态
      // usernameReadonly: true,
      // 步骤条中的当前步骤
      // 申请的id
      applyId: "",
      // 当前的步骤, 0表示新发起的..., 1, 2, 3, 4
      applyCourse: 0,
      // 保证金优惠比例列表
      marginsList: [],
      // 提交按钮的禁用标志
      disableFlg: false,
      images: ["https://s1.ax1x.com/2023/02/20/pSODfyQ.png"],
      showStandard: false,
      // active: 0,
      // 表单项数据(申请日期、客户姓名、资金账号、申请比例、申请理由)
      calendarValue: "", // 申请日期
      username: "", // 客户姓名
      useraccount: "", // 资金账号
      // 申请比例
      scaleValue: 1, // 申请比例 1,2,3,4, 1表示'交易所标准+3'
      // 申请比例的文本内容
      scaleText: "", // '交易所标准+3', '交易所标准+2', '交易所标准+1', '交易所标准+0'
      // 申请理由
      reason: "", // 申请理由
      showCalendar: false,
      showPicker: false,
      // columns: ['交易所标准+3', '交易所标准+2', '交易所标准+1', '交易所标准+0'],
      // 客户签名的64位数据
      sigImg: "",
      // 客户经理签名的64位数据
      marketSigImg: "",
      // 机构负责人签名的64位数据
      operationSigImg: "",
      // 传递的参数对象
      params: {},
      // 显示分享面板的flag
      showShare: false,
      // 分享面板的选项
      shareOptions: [{ name: "复制链接", icon: "link" }],
      // 客户签名框的开关
      showPopSignature: false,
      // 客户经理签名框的开关
      showPopMarketSignature: false,
      // 机构负责人签名框的开关
      showPopOperationSignature: false,
      // 客户经理签名后显示的机构负责人姓名
      operationName: "",
      // 运作部审批同意/不同意
      agreeRadio: "2",
      // 运作部审批不同意 -> 拒绝原因
      refuseReasonText: "",
      // 运作管理部人员姓名
      riskName: "",
    };
  },
  computed: {
    // 根据提交按钮是否已禁用对应显示按钮上的文本
    submitBtnText() {
      return this.disableFlg == false ? "提交" : "已提交";
    },
  },
  methods: {
    // 显示《保证金告知书》弹出窗口的方法
    showPopup_marginCertificate() {
      var flag = this.$refs.marginCertificateFlagRef.checked;
      if (flag == false) {
        this.showMarginCertificate = true;
        flag == true;
        // this.$refs.marginCertificateText.value = "已阅读《申请特殊交易保证金告知书》"
        this.marginCertificateValue = "已阅读《申请特殊交易保证金告知书》";
      } else {
        this.showMarginCertificate = false;
        flag == false;
        this.marginCertificateValue = "点击阅读《申请特殊交易保证金告知书》";
      }
    },
    // 选择申请比例的弹出窗口控制
    showPickerFn() {
      this.applyCourse >= 1
        ? (this.showPicker = false)
        : (this.showPicker = true);
    },
    // 相关控件的是否可编辑控制函数
    showCalendarFn() {
      // console.log('showEditableFn')
      this.applyCourse >= 1
        ? (this.showCalendar = false)
        : (this.showCalendar = true);
    },
    //获取地址栏参数，name:参数名称
    getUrlParms: function (name) {
      // var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      // var r = window.location.search.substr(1).match(reg)
      // if (r != null) return unescape(r[2])
      // return null
      let geturl = window.location.href;
      let getinfo = geturl.split("?")[1];
      let getqys = new URLSearchParams("?" + getinfo);
      return getqys.get(name);
    },
    // 客户签名按钮点击 -> 开启/关闭客户签名弹出框
    showPopup_signature() {
      if (this.applyCourse === 0) {
        this.showPopSignature = true;
      }
    },

    showPopup_standard() {
      this.showStandard = true;
    },
    asyncAddone() {},
    syncAddone() {},
    // 获取并转换需要提交的申请比例： '交易所标准+3' -> 3
    setScaleValue() {
      // 准备scaleValues, 数字型
      // let scaleValue = 1
      if (this.scaleText === "交易所标准+2") this.scaleValue = 2;
      else if (this.scaleText === "交易所标准+1") this.scaleValue = 3;
      else if (this.scaleText === "交易所标准+0") this.scaleValue = 4;
      else if (this.scaleText === "交易所标准+3") this.scaleValue = 1;

      return this.scaleValue;
    },
    // 根据scaleValue设置对应的ScaleText
    setScaleText(value) {
      if (value === 1) {
        this.scaleText = "交易所标准+3";
      } else if (value === 2) {
        this.scaleText = "交易所标准+2";
      } else if (value === 3) {
        this.scaleText = "交易所标准+1";
      } else if (value === 4) {
        this.scaleText = "交易所标准+0";
      }
    },
    // base64 --> 图片文件
    baseURLtoFile(dataurl) {
      console.log(dataurl);
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      // return new Blob([ia], {type:mime});
      let obj = new Blob([ia], { type: mime });
      // var fd = new FormData();
      // fd.append("upfile", obj, "image.png");
      return obj;
    },
    // 表单提交
    async onSubmit() {
      // console.log('values: ', values)
      // 封装表单数据对象(变更日期和scaleValue格式)
      // 变更日期形式  yyyy-mm-dd   -> yyyymmdd
      // var reg = /(\d{4})\-(\d{2})\-(\d{2})/

      let submitObj = {};
      // submitObj.calendarValue = values.calendarValue.replace(reg, '$1$2$3')
      submitObj.calendarValue = this.calendarValue;
      submitObj.username = this.username;
      submitObj.useraccount = this.useraccount;
      submitObj.scaleValue = this.setScaleValue();
      submitObj.reason = this.reason;
      submitObj.clientManager = this.weComUserId;
      console.log("submitObj", submitObj);

      // 发起步骤2：客户签名
      // 0. 校验是否签名和阅读保证金告知书
      if (this.sigImg == "") return Toast.fail("请提交手写签名！");
      if (this.$refs.marginCertificateFlagRef.checked == false)
        return Toast.fail("请确认浏览《申请特殊交易保证金告知书》");
      // 1. 先上传签名
      // let sigObj = {};
      // sigObj.type = "客户签名";
      // sigObj.sigImg = this.sigImg;
      // sigObj.id = this.applyId;
      // console.log(sigObj)
      let file = this.baseURLtoFile(this.sigImg);
      console.log(file);
      let type = "客户签名";
      let id = this.applyId;
      let form = new FormData();
      form.append("file", file);
      form.append("type", type);
      form.append("id", id);
      const res1 = await uploadSigImgAPI(form);
      console.log(res1);
      if (res1.code != 200) {
        return Toast.fail(res1.message);
      }

      // 2. 加上除签名外的其他数据
      submitObj.id = this.applyId;
      // submitObj.sigImg = this.sigImg
      // console.log(submitObj)
      const res = await clientSignAPI(submitObj);
      if (res.code != 200) return Toast.fail(res.message);
      // console.log(res)
      this.$dialog
        .alert({
          title: "提交表单",
          message: "提交表单成功!",
        })
        .then(() => {
          // submit按钮禁用
          this.disableFlg = true;
        });
    },

    // 显示日历
    onConfirm(date) {
      let mon =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      let dat = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      this.calendarValue = `${date.getFullYear()}-` + mon + "-" + dat;
      this.showCalendar = false;
    },
    // 显示选择申请比例
    onConfirmProp(value) {
      // console.log(value)
      this.scaleText = value.scaleContent;
      this.scaleValue = value.scaleId;
      this.showPicker = false;
    },
    // 申请保证金优惠比例列表, 保存到marginsList
    async getMarginsListFn() {
      const res = await getMarginStandardAPI();
      if (res.code !== 200)
        return Toast.fail({ message: "请求保证金优惠比例列表失败!" });
      this.marginsList = res.data;
    },
    // 分享面板
    onShowSelect(option) {
      // console.log(1)
      // console.log(option.name)
      // wexinShare()
      Toast(option.name);
      this.showShare = false;
    },
    // 分享到微信
    // wxShare() {
    //   // Todo ...
    // },
    // 客户签名对话框关闭 -> 保存客户签名图片64位码并关闭对话框
    closeDialog(e) {
      // 保存签名的64位数据
      // console.log(e)
      this.sigImg = e;
      this.showPopSignature = false;
    },

    // 根据this.applyId查询当前申请的相关数据
    async getApplicationByIdFn(id) {
      // console.log('getApplicationByIdFn')
      const res = await getApplicationById(id);
      console.log(res);
      if (res.code !== 200) {
        return this.$toast.fail("Error: getApplicationByIdFn ");
      }
      // console.log(res)
      // 保存响应的数据到相应的data中
      this.applyId = res.data.applyId;
      this.applyCourse = res.data.applyCourse;
      this.username = res.data.applyName;
      this.calendarValue = res.data.applyTime;
      this.useraccount = res.data.applyAccount;
      this.scaleValue = parseInt(res.data.applyType);
      this.reason = res.data.applyReason;
      this.applyCourse = res.data.applyCourse;
      this.setScaleText(this.scaleValue); // 设置scaleText
      this.refuseReasonText = res.data.applyRefuseReason; // 拒绝理由

      // 保存签名数据
      function getSignFn(signList) {
        return signList.signType === "客户签名";
      }

      if (res.data.signList != "") {
        // 保存客户签名
        this.sigImg = res.data.signList.find(getSignFn).signImg;
        // 如果有客户签名，则禁用提交状态
        if (this.sigImg != "") this.disableFlg = true;
      }
    },
    // 从地址栏中获取code参数值
    getUrlCodeParamFn() {
      // console.log(this.$route);
      // console.log(this.$route.fullPath);
      let url = this.$route.fullPath.split("?")[1];
      let obj = {};
      let arr = url.split("&");
      for (let i = 0; i < arr.length; i++) {
        let arrNew = arr[i].split("=");
        obj[arrNew[0]] = arrNew[1];
      }
      // console.log(obj["code"]);
      if (obj["id"] !== null) return obj["id"];
      return "";
    },

    // 跳出保证金告知书确认信息
    dialogConfirmFn() {
      this.$dialog
        .confirm({
          title: "申请特殊交易保证金告知书",
          messageAlign: "left",
          message:
            "一、特殊交易保证金（以下简称“特保”）是指：公司根据对客户的资信状况、交易目的、交易方式等方面的评估，对客户采取相应的保证金收取水平。\n" +
            "二、特保按品种设定四个级别的收取标准，对于申请特保的客户，需拥有良好的风险处置配合度，同时满足以下对交易资金量的要求。\n" +
            "1、良好风险配合度是指：\n" +
            "(1)日内交易不留仓，交易方式主要为套保及套利等完全对锁持仓交易；\n" +
            "(2)可主动控制好账户资金使用率及风险度；\n" +
            "(3)基本不存在被公司强平的情况，或偶尔被强平后，能及时确认强平结果；\n" +
            "(4)其他风险可控的特殊情况，如有信用保障的重点服务客户等。\n" +
            "2、资金量标准是指：\n" +
            "(1)交易所标准+3：参与交易的资金量在10万以上；\n" +
            "(2)交易所标准+2：参与交易的资金量在50万以上；\n" +
            "(3)交易所标准+1：参与交易的资金量在200万以上；\n" +
            "(4)交易所标准+0：参与交易的资金量需达到一定规模。\n" +
            "3、特保获批的客户，公司将对其交易、持仓及资金风险等情况进行持续关注。若客户未达到事先承诺的标准或风险配合度较差，公司有权取消其特保资格。\n" +
            "4、通常情况下，临近长假公司将统一取消特保优惠，所有客户按公司保证金收取标准执行。\n" +
            "三、备注：\n" +
            "(1)交易所标准+3：五家+3%，中金所股指+2%, 国债+0.3% \n" +
            "(2)交易所标准+2：五家+2%，中金所股指+1%, 国债+0.2% \n" +
            "(3)交易所标准+1：五家+1%，中金所股指+1%, 国债+0.1% \n" +
            "(4)交易所标准+0：六家+0 \n" +
            "(5)五家：上期所、能源中心、大商所、郑商所、广期所 \n" +
            "(6)六家：上期所、能源中心、大商所、郑商所、广期所、中金所 \n" +
            "公司根据市场情况，保留调整比例的权力 \n",
          width: "90%",
          theme: "round",
          confirmButtonColor: "#1989fa",
          showCancelButton: false,
        })
        .then(() => {
          // on confirm
        });
    },
  },

  // 页面加载时 -> 请求保证金优惠比例列表,并保存;
  mounted() {
    // 跳出风险告知书
    this.dialogConfirmFn();

    // 获取保证金比例列表
    this.getMarginsListFn();

    this.getApplicationByIdFn(this.getUrlCodeParamFn("id"));
  },
  components: {
    Signature,
    // FooterBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Calendar.name]: Calendar,
    [Picker.name]: Picker,
    [Dialog.name]: Dialog,
    [NoticeBar.name]: NoticeBar,
    [ShareSheet.name]: ShareSheet,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Notify.name]: Notify,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
  },
};
</script>

<style lang="less" scoped>
* {
  // touch-action: none;
}

.home-container {
  // padding-bottom: 50px;
  .banner {
    position: fixed;
    top: 0;
    z-index: 1;

    img {
      height: 100px;
    }
  }

  .content {
    margin-top: 100px;
  }

  .van-form {
    padding-bottom: 2.66666rem;
  }

  // .footer {
  //   position: fixed;
  //   .van-notice-bar {
  //     bottom: 1.33333rem;
  //     left: 0;
  //     z-index: 999;
  //   }
  // }
}

.van-notice-bar {
  bottom: 1.33333rem;
  left: 0;
}

.van-popup {
  width: 100%;
  padding: 10px;
}

.van-radio-group {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.marginNoticeText {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 30px 15px 0px 15px;

  h3 {
    text-align: center;
  }
}

// .van-notice-bar__content {
//   z-index: 999;
// }
</style>

