import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index.js';

// 引入vant全部样式
import 'vant/lib/index.less';

// 导入flexible, 用于flexible + rem 布局
import 'lib-flexible/flexible';

// 导入fastclick, 解决移动端300ms延迟的问题
import FastClick from 'fastclick';
FastClick.attach(document.body);

// vant中的图片组件
import { Image as VanImage } from 'vant';
Vue.use(VanImage);

// vant的图片懒加载
import { Lazyload } from 'vant';
Vue.use(Lazyload);

// 手写签名
import vueEsign from 'vue-esign';
Vue.use(vueEsign);

Vue.config.productionTip = false;

new Vue({
  router, // $route(属性) $router(方法)
  store, // $store
  render: h => h(App)
}).$mount('#app');
