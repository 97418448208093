import Clipboard from "clipboard";

function clipboardSuccess(){
  console.log('复制链接成功！');
}

function clipboardError(){
  console.log('复制链接失败！');
}

export default function handleCopy(text, target, onSuccess, onError){
  target = target || {}
  const clipboard = new Clipboard(target, {
    text: ()=>text
  })
  clipboard.on('success', ()=>{
    onSuccess ? onSuccess() : clipboardSuccess()
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.on('error', ()=>{
    onError ? onError() : clipboardError()
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.onClick(event)
}