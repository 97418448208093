<template>
  <div>
    <van-nav-bar :title="title" left-text="取消签名" left-arrow @click-left="closeDialog" :fixed="true" :placeholder="true" class="nav-bar" />
    <div class="canvaspanel">
      <div class="canvasborder">
        <vue-esign ref="esign" style="width:100%; height=200px" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
      </div>
      <div class="buttongroup">
        <van-button type="primary" @click="handleReset" class="empty">清空</van-button>
        <van-button type="primary" @click="handleGenerate" class="autograph">确定</van-button>
      </div>
    </div>
    <!-- <img :src="resultImg" alt="" v-show="true" style="width:100%; height=400px"> -->
  </div>
</template>

<script>
import { NavBar, Toast, Button } from 'vant'
export default {
  name: 'Esign',
  data() {
    return {
      title: '手写签名',
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
      resultImg: '',
      isCrop: false
    }
  },
  methods: {
    handleReset() {
      this.$refs.esign.reset()
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then(res => {
          this.resultImg = res
          this.$emit('close', this.resultImg)
          // console.log(this.resultImg)
        })
        .catch(err => {
          Toast.fail('请签名')
          alert(err) // 画布没有签字时会执行这里 'Not Signned'
        })
    },
    closeDialog() {
      this.resultImg = ''
      this.$emit('close', this.resultImg)
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Button.name]: Button
  },
  mounted() {
    // console.log(this)
  }
}
</script>

<style lang="less" scoped>
.canvasborder {
  border: solid 1px #ccc;
}
.canvaspanel {
  position: relative;
}

.buttongroup {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  margin-top: 10px;
}

.autograph {
  margin-left: 20px;
}
</style>
