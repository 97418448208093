<template>
  <div class="home-container">
    <!-- banner区域 -->
    <div class="banner">
      <van-image width="100%" lazy-load :src="require('../assets/banner.png')">
        <template v-slot:error>加载图片失败</template>
      </van-image>
      <!-- <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" width="100%" />
        </van-swipe-item>
      </van-swipe> -->
    </div>
    <!-- 跑马灯告知的文本区域 -->
    <div class="content">
      <van-notice-bar
        left-icon="volume-o"
        text="本线上保证金优惠申请仅限自然人客户......"
      />
      <!-- <div class="readme"> -->
      <!-- <van-cell value="注：本线上保证金优惠申请仅限自然人客户" size="small">
      </van-cell> -->
      <!-- <van-popup v-model="showStandard">
          <van-cell-group>
            <van-cell title="10万≤期货权益≤50万" value="交易所+3" />
            <van-cell title="50万≤期货权益≤200万" value="交易所+2" />
            <van-cell title="200万≤期货权益" value="交易所+1" />
          </van-cell-group>
        </van-popup> -->
      <!-- </div> -->
    </div>

    <!-- 表单区域 -->
    <van-form @submit="onSubmit">
      <!-- 选择日期 -->
      <van-field
        readonly
        clickable
        name="calendarValue"
        :value="calendarValue"
        label="申请日期"
        placeholder="选择日期"
        @click="showCalendarFn"
        :rules="[
          {
            required: true,
            message: '日期格式有误',
            pattern:
              /^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$/,
          },
        ]"
        colon
      />
      <van-calendar
        v-model="showCalendar"
        @confirm="onConfirm"
        :show-confirm="false"
      />
      <!-- 选择所属部门 -->
      <!-- <van-field label="所属部门" placeholder="请输入所属部门"  v-model="userDept"></van-field> -->
      <!-- <van-field
        v-model="userDept"
        is-link
        readonly
        label="所属部门:"
        placeholder="请选择所属部门"
        @click="showPopDept = true"
      />
      <van-popup v-model="showPopDept" round position="bottom">
        <van-cascader
          v-model="cascaderDeptValue"
          title="请选择所属部门"
          :options="deptOptions"
          @close="showPopDept = false"
          @change="onCascaderChange"
          @finish="onCascaderFinish"
        />
      </van-popup> -->

      <!-- 客户姓名 -->
      <van-field
        ref="usernameRef"
        :readonly="usernameReadonly"
        v-model="username"
        name="username"
        label="客户姓名"
        placeholder="请输入客户姓名"
        :rules="[
          {
            required: true,
            message: '姓名格式有误',
            pattern: /[\u4e00-\u9fa5]/,
          },
        ]"
        colon
      />

      <!-- 资金账号 -->
      <van-field
        ref="useraccountRef"
        :readonly="useraccountReadonly"
        v-model="useraccount"
        name="useraccount"
        label="资金账号"
        placeholder="请输入资金账号"
        :rules="[
          { required: true, message: '资金账号格式有误', pattern: /^\d{4,8}$/ },
        ]"
        colon
      />

      <!-- 申请保证金优惠对应比例 -->
      <van-field
        readonly
        clickable
        label="申请比例"
        name="scaleText"
        :value="scaleText"
        placeholder="选择申请比例"
        @click="showPickerFn"
        colon
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="marginsList"
          value-key="scaleContent"
          @cancel="showPicker = false"
          @confirm="onConfirmProp"
          :default-index="0"
        />
      </van-popup>

      <!-- 申请理由文本 -->
      <van-field
        ref="reasonRef"
        :readonly="reasonReadonly"
        v-model="reason"
        name="reason"
        type="textarea"
        label="申请理由"
        placeholder="申请理由"
        :rules="[{ required: true, message: '请填写申请理由' }]"
        rows="4"
        autosize
        input-align="left"
        colon
        maxlength="300"
      />

      <!-- 客户签名区域 -->
      <!-- <van-popup
        v-model="showMarginCertificate"
        :style="{ height: '60%' }"
        get-container="body"
        position="center"
      >
        <van-image src="https://s1.ax1x.com/2023/03/29/ppcQRTU.png"></van-image>
      </van-popup> -->
      <van-cell is-link v-show="applyId !== '' && applyCourse >= 1 ">客户签名</van-cell>
      <!--
      <van-popup
        v-model="showPopSignature"
        :style="{ height: '55%' }"
        get-container="body"
        position="top"
      >
        <div style="margin-top: 50px">
          <p
            style="
              padding: 0 10px;
              text-indent: 2em;
              color: #333;
              line-height: 16px;
            "
          >
            本人深知期货市场的风险性，并将严格按照贵公司风险控制标准执行，本人认可贵公司有权根据期货交易所、结算机构的规定、市场情况，因本人风险配合度差、资金持续不达标以及贵公司认定其他需要调整保证金比例的情况，本人同意贵公司调整本人期货账户保证金比例，并愿意承担由此造成的一切风险！
          </p>
        </div>
        <Signature @close="closeDialog"> </Signature>
      </van-popup>
      <van-cell
        v-show="applyId !== '' && applyCourse == 0"
        style="font-size: 12px; line-height: 12px; padding: 0 0.426rem"
      >
        <van-checkbox
          v-model="marginCertificateFlag"
          ref="marginCertificateFlagRef"
          style="font-size: 12px"
          shape="square"
          icon-size="12px"
        >
          <van-cell
            @click="showPopup_marginCertificate"
            ref="marginCertificateText"
            :value="marginCertificateValue"
            style="font-size: 12px; padding-left: 0"
          ></van-cell>
        </van-checkbox>
      </van-cell> -->
      <img
        :src="sigImg"
        alt=""
        v-show="true"
        style="width:100%; height=400px; object-fit: contain;"
      />

      <!-- 客户经理签名区域 -->
      <van-cell
        is-link
        @click="showPopup_marketSignature"
        v-show="applyId !== '' && applyCourse >= 1"
        >客户经理签名</van-cell
      >
      <van-popup
        v-model="showPopMarketSignature"
        :style="{ height: '40%' }"
        get-container="body"
        position="bottom"
      >
        <Signature @close="closeMarketDialog"> </Signature>
      </van-popup>
      <img
        :src="marketSigImg"
        alt=""
        v-show="true"
        style="width:100%; height=400px; object-fit: contain;"
      />
      <!-- 选择部门 -->
      <van-field
        readonly
        clickable
        v-show="applyId !== '' && applyCourse >= 1"
        name="pickerDept"
        :value="pickerOperationDept"
        label="所属市场机构"
        placeholder="点击选择市场机构"
        @click="
          applyId !== '' && applyCourse == 1 && disableFlg == false
            ? (showPickerDept = true)
            : (showPickerDept = false)
        "
      />
      <van-popup v-model="showPickerDept" position="bottom">
        <van-picker
          show-toolbar
          :columns="columnsDept"
          value-key="name"
          @confirm="onConfirmDept"
          @cancel="showPickerDept = false"
        />
      </van-popup>

      <!-- 选择机构负责人 -->
      <van-field
        readonly
        clickable
        v-show="applyId !== '' && applyCourse == 1"
        name="pickerOperation"
        :value="pickerOperationValue"
        label="机构负责人"
        placeholder="点击选择机构负责人"
        @click="
          applyId !== '' && applyCourse == 1 && disableFlg == false
            ? (showPickerOperation = true)
            : (showPickerOperation = false)
        "
      />
      <van-popup v-model="showPickerOperation" position="bottom">
        <van-picker
          show-toolbar
          :columns="columnsOperation"
          value-key="name"
          @confirm="onConfirmOperation"
          @cancel="showPickerOperation = false"
        />
      </van-popup>
      <!-- <van-picker title="机构負責人`" show-toolbar :columns="columns" /> -->
      <!-- <van-field label="机构负责人" placeholder="请输入机构负责人姓名" v-model="operationName"
        v-show="applyId !== '' && applyCourse === 1 && marketSigImg !== ''"></van-field> -->

      <!-- 机构负责人签名区域 -->
      <van-cell
        is-link
        @click="showPopup_operationSignature"
        v-show="applyId !== '' && applyCourse >= 2 && disableFlg == false"
        >机构负责人签名</van-cell
      >
      <van-popup
        v-model="showPopOperationSignature"
        :style="{ height: '40%' }"
        get-container="body"
        position="bottom"
      >
        <Signature @close="closeOperationDialog"> </Signature>
      </van-popup>
      <img
        :src="operationSigImg"
        alt=""
        v-show="true"
        style="width:100%; height=400px; object-fit: contain;"
      />
      <!-- <van-field label="运作管理部" placeholder="请输入运作管理部审批人姓名" v-model="riskName"
        v-show="applyId !== '' && applyCourse === 2 && operationSigImg !== ''"></van-field> -->

      <!-- 运作部意见：同意/不同意 -->
      <div class="riskDeptArea" v-show="applyId !== '' && applyCourse >= 3">
        <van-cell title="运作部审批意见"></van-cell>
        <van-radio-group
          v-model="agreeRadio"
          direction="horizontal"
          :disabled="radioRiskDisabled"
        >
          <van-radio name="1" icon-size="30px">不同意</van-radio>
          <van-radio name="2" icon-size="30px">同意</van-radio>
        </van-radio-group>
        <van-field
          v-show="agreeRadio === '1' || applyCourse === 5"
          v-model="refuseReasonText"
          name="refuseReason"
          type="textarea"
          label="拒绝原因"
          placeholder="请填写拒绝原因"
          :rules="[{ required: false, message: '未填写拒绝原因' }]"
          rows="4"
          autosize
          input-align="left"
          maxlength="300"
          :disabled="radioRiskDisabled"
        />
      </div>

      <div style="margin: 16px">
        <!-- 提交按钮 -->
        <van-button
          round
          block
          ref="submitBtnRef"
          type="info"
          native-type="submit"
          name="submit"
          class="submitBtn"
          :disabled="disableFlg"
          >{{ submitBtnText }}</van-button
        >
      </div>
    </van-form>

    <!-- 页面加载时跳出弹出窗口 -->
    <!-- <van-dialog v-model="riskConfirmShowFlag" title="申请特殊交易保证金告知书" message="一、特殊交易保证金（以下简称“特保”）是指：公司根据对客户的资信状况、交易目的、交易方式等方面的评估，对客户采取相应的保证金收取水平。\n" >
      111
    </van-dialog> -->
  </div>
</template>

<script>
import { returnUrl } from "@/utils/request.js";
import Signature from "components/Signature.vue";
// import { wexinShare } from '@/api/wxapi'
// import FooterBar from 'components/FooterBar.vue'
// import Clipboard from 'clipboard'
import handleCopy from "@/utils/clipboard";
import {
  getUserIdAPI,
  getMarginStandardAPI,
  initApplicationAPI,
  clientSignAPI,
  uploadSigImgAPI,
  marketSignConfirm,
  // operationSignConfirm,
  riskDepConfirmAPI,
  riskDepRefuseAPI,
  getWxUserID,
  getUserIdAndCodeAPI,
  mechanismSignConfirm,
  getDepartmentManagerAPI,
} from "@/api";
// import Signature from 'components/Signature.vue'
import {
  Checkbox,
  Swipe,
  SwipeItem,
  Popup,
  Button,
  Cell,
  CellGroup,
  Row,
  Col,
  Form,
  Field,
  Calendar,
  Picker,
  Dialog,
  NoticeBar,
  Toast,
  ShareSheet,
  Step,
  Steps,
  Notify,
  RadioGroup,
  Radio,
  Lazyload,
  CountDown,
  Image,
  Cascader,
} from "vant";

export default {
  name: "Home",
  data() {
    return {
      // 运作部审批同意/不同意radio切换的disabled控制
      radioRiskDisabled: false,
      // 弹出选择部门的开关
      showPickerDept: false,
      // 选择的所属部门名称
      pickerOperationDept: "",
      // 选择的所属部门id
      pickerOperationDeptId: "",
      // 请求回来的部门列表，元素是对象
      columnsDept: [],
      // 请求回来的部门id

      // 选择部门cascader的显示字段名
      // deptFieldNames: {
      //   text: 'deptName',
      //   value: 'code',
      //   children: 'items'
      // },
      // 选择的部门
      cascaderDeptValue: "",
      // 是否显示选择部门的pop
      showPopDept: false,
      // 待选择的部门列表
      deptOptions: [],
      deptOptions1: [
        {
          id: 54,
          name: "期货公司上海市场",
          parentid: 661,
          order: 100001000,
          department_leader: ["GaoXiangYang"],
        },
        {
          id: 55,
          name: "期货公司分支机构",
          parentid: 661,
          order: 100000000,
          department_leader: ["GaoXiangYang"],
        },
        {
          id: 57,
          name: "产业客户服务中心",
          parentid: 54,
          order: 100000000,
          department_leader: ["YeFang"],
        },
        {
          id: 58,
          name: "产业二部",
          parentid: 54,
          order: 99999625,
          department_leader: ["WangChunYan"],
        },
        {
          id: 59,
          name: "金融期货部",
          parentid: 54,
          order: 100000250,
          department_leader: ["ShenJia"],
        },
        {
          id: 62,
          name: "浙江大区",
          parentid: 55,
          order: 100031000,
          department_leader: ["BianQunFei"],
        },
        {
          id: 63,
          name: "中原分公司",
          parentid: 349,
          order: 100027000,
          department_leader: ["NiuGuangQing"],
        },
        {
          id: 66,
          name: "济南营业部",
          parentid: 349,
          order: 100029000,
          department_leader: ["QuPing"],
        },
        {
          id: 67,
          name: "天津营业部",
          parentid: 647,
          order: 100001000,
          department_leader: ["HaoYong"],
        },
        {
          id: 70,
          name: "日照营业部",
          parentid: 349,
          order: 100031000,
          department_leader: ["SuYan"],
        },
        {
          id: 72,
          name: "唐山营业部",
          parentid: 647,
          order: 100000000,
          department_leader: ["HaoYong"],
        },
        {
          id: 75,
          name: "赤峰营业部",
          parentid: 349,
          order: 100024000,
          department_leader: ["WangBiao"],
        },
        {
          id: 78,
          name: "北京营业部",
          parentid: 647,
          order: 100002000,
          department_leader: ["GaoZheng"],
        },
        {
          id: 81,
          name: "沈阳营业部",
          parentid: 349,
          order: 100023000,
          department_leader: ["YangXiaoYun"],
        },
        {
          id: 83,
          name: "长沙营业部",
          parentid: 349,
          order: 100028000,
          department_leader: ["YangWenShang"],
        },
        {
          id: 84,
          name: "南昌营业部",
          parentid: 349,
          order: 100030000,
          department_leader: ["WuChunGuang"],
        },
        {
          id: 85,
          name: "郑州营业部",
          parentid: 349,
          order: 100014500,
          department_leader: ["FangHongJiang"],
        },
        {
          id: 86,
          name: "西安营业部",
          parentid: 349,
          order: 100019000,
          department_leader: ["LiuWei"],
        },
        {
          id: 87,
          name: "山西分公司",
          parentid: 349,
          order: 100015000,
          department_leader: ["GuanKai"],
        },
        {
          id: 119,
          name: "互联网事业部",
          parentid: 54,
          order: 99997625,
          department_leader: ["SunKun"],
        },
        {
          id: 121,
          name: "陆家嘴环路营业部",
          parentid: 54,
          order: 100026375,
          department_leader: ["YuJianFen"],
        },
        {
          id: 122,
          name: "襄阳南路营业部",
          parentid: 54,
          order: 100026187,
          department_leader: ["ZhangSheng"],
        },
        {
          id: 124,
          name: "华中大区",
          parentid: 55,
          order: 100026750,
          department_leader: ["XuHuiLin"],
        },
        {
          id: 125,
          name: "武汉营业部",
          parentid: 124,
          order: 100000000,
          department_leader: ["XuHuiLin"],
        },
        {
          id: 126,
          name: "武汉金融事业部",
          parentid: 124,
          order: 99999000,
          department_leader: ["HuangJingJing"],
        },
        {
          id: 127,
          name: "武汉农产品事业部",
          parentid: 124,
          order: 99998000,
          department_leader: ["WangHuaFu"],
        },
        {
          id: 128,
          name: "浙江分公司",
          parentid: 62,
          order: 100000000,
          department_leader: ["BianQunFei"],
        },
        {
          id: 129,
          name: "杭州营业部",
          parentid: 62,
          order: 99999000,
          department_leader: [],
        },
        {
          id: 130,
          name: "宁波营业部",
          parentid: 62,
          order: 99998000,
          department_leader: ["LinLei"],
        },
        {
          id: 131,
          name: "浙分互联网营销中心",
          parentid: 128,
          order: 99997000,
          department_leader: [],
        },
        {
          id: 132,
          name: "浙分财富管理部",
          parentid: 128,
          order: 99996000,
          department_leader: ["JiangRunQing"],
        },
        {
          id: 133,
          name: "江苏大区",
          parentid: 55,
          order: 100030500,
          department_leader: ["GaoWei"],
        },
        {
          id: 134,
          name: "江苏分公司",
          parentid: 133,
          order: 100000000,
          department_leader: [],
        },
        {
          id: 135,
          name: "合肥营业部",
          parentid: 133,
          order: 99999000,
          department_leader: [],
        },
        {
          id: 136,
          name: "南通营业部",
          parentid: 133,
          order: 99998000,
          department_leader: ["ZhaoXiaoDong"],
        },
        {
          id: 137,
          name: "常州营业部",
          parentid: 133,
          order: 99997000,
          department_leader: ["JiNan"],
        },
        {
          id: 138,
          name: "无锡营业部",
          parentid: 133,
          order: 99996000,
          department_leader: ["LvYao"],
        },
        {
          id: 139,
          name: "无锡事业部",
          parentid: 134,
          order: 99995000,
          department_leader: ["GuoZhun"],
        },
        {
          id: 140,
          name: "江苏财富管理部",
          parentid: 134,
          order: 99994000,
          department_leader: ["XuCaiYang"],
        },
        {
          id: 141,
          name: "华南大区",
          parentid: 55,
          order: 100028500,
          department_leader: ["ChenZhiHang"],
        },
        {
          id: 142,
          name: "深圳营业部",
          parentid: 141,
          order: 100000000,
          department_leader: ["ChenZhiHang"],
        },
        {
          id: 143,
          name: "厦门营业部",
          parentid: 141,
          order: 99999000,
          department_leader: ["ChaiXinXin"],
        },
        {
          id: 349,
          name: "机构管理部直管部门",
          parentid: 55,
          order: 100030250,
          department_leader: ["GaoXiangYang"],
        },
        {
          id: 351,
          name: "中原一部",
          parentid: 63,
          order: 100000000,
          department_leader: ["WangXia"],
        },
        {
          id: 352,
          name: "北方财富管理部",
          parentid: 72,
          order: 100002000,
          department_leader: ["MengDeGang"],
        },
        {
          id: 353,
          name: "唐山事业部",
          parentid: 72,
          order: 100001000,
          department_leader: ["WangYaLi"],
        },
        {
          id: 617,
          name: "武汉营业部市场一部",
          parentid: 125,
          order: 100000000,
          department_leader: ["LiangKaiLi"],
        },
        {
          id: 640,
          name: "扬州营业部",
          parentid: 133,
          order: 99995000,
          department_leader: ["YeXuXia"],
        },
        {
          id: 644,
          name: "西南大区",
          parentid: 55,
          order: 100000000,
          department_leader: [],
        },
        {
          id: 645,
          name: "成都分公司",
          parentid: 644,
          order: 100000000,
          department_leader: ["XiangWei"],
        },
        {
          id: 646,
          name: "重庆营业部",
          parentid: 644,
          order: 99999000,
          department_leader: ["XiangWei"],
        },
        {
          id: 647,
          name: "京津冀大区",
          parentid: 55,
          order: 99999000,
          department_leader: [],
        },
        {
          id: 648,
          name: "视频中心",
          parentid: 128,
          order: 99995000,
          department_leader: [],
        },
        {
          id: 653,
          name: "宁波事业部",
          parentid: 130,
          order: 100000000,
          department_leader: [],
        },
        {
          id: 657,
          name: "互联网金融一部",
          parentid: 54,
          order: 99996625,
          department_leader: [],
        },
        {
          id: 661,
          name: "期货公司市场部门",
          parentid: 28,
          order: 99991000,
          department_leader: [],
        },
      ],
      // 客户经理所属部门
      userDepts: "",
      // 是否显示客户签名的图片
      // showSigImg: false;
      // 客户经理发起时的风险告知书dialog
      // riskConfirmShowFlag: true,
      // 运作部审批人对应的id
      riskDepUserId: "",
      // 选择的机构负责人姓名
      pickerOperationValue: "",
      // 选择的机构负责人userid
      pickerOperationUserid: "",
      // 是否显示选择机构负责人Picker的开关
      showPickerOperation: false,
      // 机构负责人姓名数组
      columnsOperation: [],
      // 返回的机构负责人{name, userid}数组
      columnsOperationIds: [],
      // 提交的机构负责人对应的userid
      operationId: "",
      // 企业微信认证的code
      weComCode: "",
      // 企业微信的申请用户
      weComUserId: "",
      // 企业微信的申请用户对应部门成员列表
      weComDeptUserList: [],

      marginCertificateValue: "点击浏览《申请特殊交易保证金告知书》",
      // 是否显示《保证金告知书》弹出层的开关
      showMarginCertificate: false,
      // 是否已浏览《保证金告知书》的开关
      marginCertificateFlag: false,
      // 控制客户姓名输入框的只读状态
      // usernameReadonly: true,
      // 步骤条中的当前步骤
      // 申请的id
      applyId: "",
      // 当前的步骤, 0表示新发起的..., 1, 2, 3, 4
      applyCourse: 0,
      // 保证金优惠比例列表
      marginsList: [],
      // 提交按钮的禁用标志
      disableFlg: false,
      images: ["https://s1.ax1x.com/2023/02/20/pSODfyQ.png"],
      showStandard: false,
      // active: 0,
      // 表单项数据(申请日期、客户姓名、资金账号、申请比例、申请理由)
      calendarValue: "", // 申请日期
      username: "", // 客户姓名
      useraccount: "", // 资金账号
      // 申请比例
      scaleValue: 1, // 申请比例 1,2,3,4, 1表示'交易所标准+3'
      // 申请比例的文本内容
      scaleText: "", // '交易所标准+3', '交易所标准+2', '交易所标准+1', '交易所标准+0'
      // 申请理由
      reason: "", // 申请理由
      showCalendar: false,
      showPicker: false,
      // columns: ['交易所标准+3', '交易所标准+2', '交易所标准+1', '交易所标准+0'],
      // 客户签名的64位数据
      sigImg: "",
      // 客户经理签名的64位数据
      marketSigImg: "",
      // 机构负责人签名的64位数据
      operationSigImg: "",
      // 传递的参数对象
      params: {},
      // 显示分享面板的flag
      showShare: false,
      // 分享面板的选项
      shareOptions: [{ name: "复制链接", icon: "link" }],
      // 客户签名框的开关
      showPopSignature: false,
      // 客户经理签名框的开关
      showPopMarketSignature: false,
      // 机构负责人签名框的开关
      showPopOperationSignature: false,
      // 客户经理签名后显示的机构负责人姓名
      operationName: "",
      // 运作部审批同意/不同意
      agreeRadio: "0",
      // 运作部审批不同意 -> 拒绝原因
      refuseReasonText: "",
      // 运作管理部人员姓名
      // riskName: "",
      // 接口请求回来的提交按钮状态
      btnStatus: true,
    };
  },
  computed: {
    // 根据提交按钮是否已禁用对应显示按钮上的文本
    submitBtnText() {
      return this.disableFlg == false ? "提交" : "已提交";
    },
    // 用户姓名是否只读
    usernameReadonly() {
      return this.applyCourse >= 1;
    },
    // 资金账号是否只读
    useraccountReadonly() {
      return this.applyCourse >= 1;
    },
    // 申请理由是否只读
    reasonReadonly() {
      return this.applyCourse >= 1;
    },
  },
  methods: {
    // 通过部门id， 获取部门负责人
    async getDeptManagerFn(deptId) {
      const res = await getDepartmentManagerAPI(deptId);
      // console.log(res);
      // 准备 columnsOperation
      for (var i = 0; i < res.data.length; i++) {
        this.columnsOperation.push(res.data[i]);
      }
    },
    // 选择部门的pop
    onConfirmDept(obj) {
      this.showPickerDept = false; // 关闭选择部门pop
      this.pickerOperationDept = obj.name; // 保存选择的部门名称
      this.pickerOperationDeptId = obj.id; // 保存选择的部门id
      this.getDeptManagerFn(this.pickerOperationDeptId); // 通过部门id获取部门负责人
    },
    // 选择部门的级联选择器
    onCascaderChange({ value }) {
      // console.log(value);
    },
    // 选择部门的级联选择器选择完毕后，会触发finish事件
    onCascaderFinish({ selectedOptions }) {
      // console.log("onCascaderFinish");
      this.showPopDept = false;
      console.log(selectedOptions);
      this.userDept = selectedOptions[selectedOptions.length - 1].text;
    },
    // 点击选择机构负责人的事件
    onConfirmOperation(obj) {
      console.log(obj);
      // if (this.pickerOperationDept == ''){
      //   return this.$toast.fail("请先选择部门");
      // }
      // 显示所选机构负责人的姓名
      this.pickerOperationValue = obj.name;
      // 保存所选机构负责人的userid
      this.pickerOperationUserid = obj.userid;
      // for (var i = 0; i < this.columnsOperationIds.length; i++) {
      //   if (this.pickerOperationValue == this.columnsOperationIds[i].name) {
      //     this.operationId = this.columnsOperationIds[i].userid;
      //   }
      // }
      this.showPickerOperation = false;
    },
    // 显示《保证金告知书》弹出窗口的方法
    showPopup_marginCertificate() {
      var flag = this.$refs.marginCertificateFlagRef.checked;
      if (flag == false) {
        this.showMarginCertificate = true;
        flag == true;
        // this.$refs.marginCertificateText.value = "已阅读《申请特殊交易保证金告知书》"
        this.marginCertificateValue = "已阅读《申请特殊交易保证金告知书》";
      } else {
        this.showMarginCertificate = false;
        flag == false;
        this.marginCertificateValue = "点击阅读《申请特殊交易保证金告知书》";
      }
    },
    // 选择申请比例的弹出窗口控制
    showPickerFn() {
      this.applyCourse >= 1
        ? (this.showPicker = false)
        : (this.showPicker = true);
    },
    // 相关控件的是否可编辑控制函数
    showCalendarFn() {
      // console.log('showEditableFn')
      this.applyCourse >= 1
        ? (this.showCalendar = false)
        : (this.showCalendar = true);
    },
    //获取地址栏参数，name:参数名称
    getUrlParms: function (name) {
      // var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      // var r = window.location.search.substr(1).match(reg)
      // if (r != null) return unescape(r[2])
      // return null
      let geturl = window.location.href;
      let getinfo = geturl.split("?")[1];
      let getqys = new URLSearchParams("?" + getinfo);
      return getqys.get(name);
    },
    // 客户签名按钮点击 -> 开启/关闭客户签名弹出框
    showPopup_signature() {
      if (this.applyCourse === 0) {
        this.showPopSignature = true;
      }
    },
    // 客户经理签名按钮点击 -> 开启/关闭客户经理签名弹出框
    showPopup_marketSignature() {
      // if (this.applyCourse === 1 && this.disableFlg == false) {
      //   this.showPopMarketSignature = true;
      // }
      this.showPopMarketSignature = true;
    },
    // 机构负责人签名按钮点击 -> 开启/关闭机构负责人签名弹出框
    showPopup_operationSignature() {
      if (this.applyCourse === 2 && this.disableFlg == false) {
        this.showPopOperationSignature = true;
      }
    },
    // showPopup_standard() {
    //   this.showStandard = true;
    // },
    asyncAddone() {},
    syncAddone() {},
    // 获取并转换需要提交的申请比例： '交易所标准+3' -> 3
    setScaleValue() {
      // 准备scaleValues, 数字型
      // let scaleValue = 1
      if (this.scaleText === "交易所标准+2") this.scaleValue = 2;
      else if (this.scaleText === "交易所标准+1") this.scaleValue = 3;
      else if (this.scaleText === "交易所标准+0") this.scaleValue = 4;
      else if (this.scaleText === "交易所标准+3") this.scaleValue = 1;

      return this.scaleValue;
    },
    // 根据scaleValue设置对应的ScaleText
    setScaleText(value) {
      if (value === 1) {
        this.scaleText = "交易所标准+3";
      } else if (value === 2) {
        this.scaleText = "交易所标准+2";
      } else if (value === 3) {
        this.scaleText = "交易所标准+1";
      } else if (value === 4) {
        this.scaleText = "交易所标准+0";
      }
    },
    // base64 --> 图片文件
    baseURLtoFile(dataurl) {
      console.log(dataurl);
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      // return new Blob([ia], {type:mime});
      let obj = new Blob([ia], { type: mime });
      // var fd = new FormData();
      // fd.append("upfile", obj, "image.png");
      return obj;
    },
    // 表单提交
    async onSubmit() {
      // console.log('values: ', values)
      // 封装表单数据对象(变更日期和scaleValue格式)
      // 变更日期形式  yyyy-mm-dd   -> yyyymmdd
      // var reg = /(\d{4})\-(\d{2})\-(\d{2})/

      let submitObj = {};
      // submitObj.calendarValue = values.calendarValue.replace(reg, '$1$2$3')
      submitObj.calendarValue = this.calendarValue;
      submitObj.username = this.username;
      submitObj.useraccount = this.useraccount;
      submitObj.scaleValue = this.setScaleValue();
      submitObj.reason = this.reason;
      submitObj.clientManager = this.weComUserId;
      submitObj.userDept = this.userDept;
      if (submitObj.userDept == "") return Toast.fail("请输入所属部门");
      // console.log("submitObj", submitObj);

      if (this.applyCourse == 0 && this.applyId === "") {
        // 发起步骤1：客户经理申请发起请求，获得applyId
        // 发起请求，获得applyId
        const res = await initApplicationAPI(submitObj);
        if (res.code !== 200) return Toast.fail(res.message);
        // 请求成功，保存applyId
        this.applyId = res.data;
        // console.log(this.applyId);

        // 跳出confirm对话框，进行复制链接
        this.$dialog
          .alert({
            title: "复制链接",
            message: "复制链接发给客户",
          })
          .then(() => {
            // 复制链接
            var urlText =
              "保证金优惠申请链接地址: " +
              returnUrl +
              "/clienthome?id=" +
              this.applyId;
            var submitBtnEle = this.$refs.submitBtnRef;
            handleCopy(
              urlText,
              submitBtnEle,
              () => {
                // console.log('复制成功回调函数')
                Notify("成功复制链接：" + urlText + ",请发送给客户！");
              },
              () => {
                // console.log('复制失败回调函数')
                return Notify("复制链接失败！");
              }
            );
          });

        // submit按钮禁用
        this.disableFlg = true;
      } else if (this.applyCourse == 0 && this.applyId !== "") {
        // 发起步骤2：客户签名发起请求
        // 0. 校验是否签名和阅读保证金告知书
        if (this.sigImg == "") return Toast.fail("请提交手写签名！");
        if (this.$refs.marginCertificateFlagRef.checked == false)
          return Toast.fail("请确认浏览《申请特殊交易保证金告知书》");
        // 1. 先上传客户签名
        // let sigObj = {};
        // sigObj.type = "客户签名";
        // sigObj.sigImg = this.sigImg;
        // sigObj.id = this.applyId;
        // console.log(sigObj)
        // const { data: res1 } = await uploadSigImgAPI(sigObj);
        let file = this.baseURLtoFile(this.sigImg);
        console.log(file);
        let type = "客户签名";
        let id = this.applyId;
        let form = new FormData();
        form.append("file", file);
        form.append("type", type);
        form.append("id", id);
        const res1 = await uploadSigImgAPI(form);
        console.log(res1);
        if (res1.code != 200) return Toast.fail(res1.message);

        // 2. 加上除签名外的其他数据
        submitObj.id = this.applyId;
        // submitObj.sigImg = this.sigImg
        // console.log(submitObj)
        const { data: res } = await clientSignAPI(submitObj);
        if (res.code != 200) return Toast.fail(res.message);
        // console.log(res)
        this.$dialog
          .alert({
            title: "提交表单",
            message: "提交表单成功!",
          })
          .then(() => {
            // submit按钮禁用
            this.disableFlg = true;
          });
      } else if (this.applyCourse === 1 && this.applyId !== "") {
        // 提前判断下是否已提交过，如果已提交过则禁用提交按钮
        // 步骤3：客户经理签名，并请求申请的id， 机构负责人的userid， 机构的部门id
        // let marketSigObj = {};
        // marketSigObj.type = "客户经理签名";
        // marketSigObj.sigImg = this.marketSigImg;
        // marketSigObj.id = this.applyId;
        // marketSigObj.operationId = this.operationId;
        // console.log(marketSigObj)
        console.log(this.marketSigImg);
        if (this.marketSigImg == "") {
          return Toast.fail("请手写签名！");
        }
        let file = this.baseURLtoFile(this.marketSigImg);
        console.log(file);
        let type = "客户经理签名";
        let id = this.applyId;
        let form = new FormData();
        form.append("file", file);
        form.append("type", type);
        form.append("id", id);
        const res1 = await uploadSigImgAPI(form);
        console.log(res1);
        // const res1 = await uploadSigImgAPI(marketSigObj);
        if (res1.code != 200) return Toast.fail(res1.message);

        // 正常提交客户经理签名后，发起签字确认，修改对应的applyCourse=2
        // console.log(this.applyId)
        let marketSignConfirmObj = {};
        // 当前申请表的id
        marketSignConfirmObj.id = this.applyId;
        // 当前申请表的部门负责人id
        marketSignConfirmObj.mechanism = this.pickerOperationUserid;
        // 当前申请表的部门id
        marketSignConfirmObj.userDept = this.pickerOperationDeptId;
        if (
          marketSignConfirmObj.mechanism == "" ||
          marketSignConfirmObj.mechanism == null
        )
          return Toast.fail("请选择机构负责人！");
        if (
          marketSignConfirmObj.userDept == "" ||
          marketSignConfirmObj.userDept == null
        )
          return Toast.fail("请选择机构！");
        const res2 = await marketSignConfirm(marketSignConfirmObj);
        if (res2.code != 200) return Toast.fail(res2.message);
        console.log(res2);

        this.$dialog
          .alert({
            title: "提交表单",
            message: "提交表单成功!",
          })
          .then(() => {
            // submit按钮禁用
            this.disableFlg = true;
          });
      } else if (this.applyCourse === 2 && this.applyId !== "") {
        // 步骤3：机构负责人签名并上传
        // let operationSigObj = {};
        // operationSigObj.type = "机构负责人签名";
        // operationSigObj.sigImg = this.operationSigImg;
        // operationSigObj.id = this.applyId;
        if (this.operationSigImg == "" || this.operationSigImg == null) {
          return Toast.fail("机构负责人请签名！");
        }
        let file = this.baseURLtoFile(this.operationSigImg);
        console.log(file);
        let type = "机构负责人签名";
        let id = this.applyId;
        let form = new FormData();
        form.append("file", file);
        form.append("type", type);
        form.append("id", id);
        const res1 = await uploadSigImgAPI(form);
        console.log(res1);
        // console.log(operationSigObj)
        // const res1 = await uploadSigImgAPI(operationSigObj);
        if (res1.code != 200) return Toast.fail(res1.message);

        // 正常提交机构负责人签名后，发起签字确认，修改对应的applyCourse=3
        // console.log(this.applyId)
        let operationSignConfirmObj = {};
        operationSignConfirmObj.id = this.applyId;
        // operationSignConfirmObj.operate = this.riskName;
        if (operationSignConfirmObj.operate === "")
          return Toast.fail("请输入运作管理部审批人姓名！");
        const res2 = await mechanismSignConfirm(operationSignConfirmObj);
        if (res2.code != 200) return Toast.fail(res2.message);

        this.$dialog
          .alert({
            title: "提交表单",
            message: "提交表单成功!",
          })
          .then(() => {
            // submit按钮禁用
            this.disableFlg = true;
          });
      } else if (this.applyCourse === 3 && this.applyId !== "") {
        // 步骤4：运作管理部审批，不同意写明原因
        if (this.agreeRadio === "2") {
          // 同意
          let riskDeptConfirmObj = {};
          riskDeptConfirmObj.id = this.applyId;
          // riskDeptConfirmObj.agree = this.agreeRadio === "2" ? "同意" : "不同意";
          riskDeptConfirmObj.operate = this.riskDepUserId;

          // console.log(riskDeptConfirmObj);
          const res = await riskDepConfirmAPI(riskDeptConfirmObj);
          if (res.code != 200) return Toast.fail(res.message);
        } else if (this.agreeRadio === "1") {
          // 不同意必须填写理由
          if(this.refuseReasonText == ""){
            return this.$toast.fail("请填写拒绝理由");
          }
          // 不同意
          let refuseReasonObj = {};
          refuseReasonObj.id = this.applyId;
          refuseReasonObj.refuseReason = this.refuseReasonText;
          refuseReasonObj.operate = this.riskDepUserId;

          const res2 = await riskDepRefuseAPI(refuseReasonObj);
          if (res2.code !== 200) return Toast.fail(res2.message);
        } else {
          return;
        }

        this.$dialog
          .alert({
            title: "提交表单",
            message: "提交表单成功!",
          })
          .then(() => {
            // submit按钮禁用
            this.disableFlg = true;
          });
      }
    },
    // 显示日历
    onConfirm(date) {
      let mon =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      let dat = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      this.calendarValue = `${date.getFullYear()}-` + mon + "-" + dat;
      this.showCalendar = false;
    },
    // 显示选择申请比例
    onConfirmProp(value) {
      // console.log(value)
      this.scaleText = value.scaleContent;
      this.scaleValue = value.scaleId;
      this.showPicker = false;
    },
    // 申请保证金优惠比例列表, 保存到marginsList
    async getMarginsListFn() {
      const res = await getMarginStandardAPI();
      if (res.code !== 200)
        return Toast.fail({ message: "请求保证金优惠比例列表失败!" });
      this.marginsList = res.data;
    },
    // 分享面板
    onShowSelect(option) {
      // console.log(1)
      // console.log(option.name)
      // wexinShare()
      Toast(option.name);
      this.showShare = false;
    },
    // 分享到微信
    // wxShare() {
    //   // Todo ...
    // },
    // 客户签名对话框关闭 -> 保存客户签名图片64位码并关闭对话框
    closeDialog(e) {
      // 保存签名的64位数据
      // console.log(e)
      this.sigImg = e;
      this.showPopSignature = false;
    },
    // 客户经理签名对话框关闭 -> 保存客户经理签名图片64位码并关闭对话框
    closeMarketDialog(e) {
      this.marketSigImg = e;
      this.showPopMarketSignature = false;
    },
    // 机构负责人签名对话框关闭 -> 保存机构负责人签名图片的64位码并关闭对话框
    closeOperationDialog(e) {
      this.operationSigImg = e;
      this.showPopOperationSignature = false;
    },
    // 根据this.applyId查询当前申请的相关数据
    async getApplicationByIdFn(code, id) {
      // console.log(code);
      // console.log(id);
      // console.log('getApplicationByIdFn')
      const res = await getUserIdAndCodeAPI(code, id);
      // console.log('getUserIdAndCodeAPI: --> ' + res);
      // console.log(res);
      // alert(res.data.applyCourse)
      // this.$dialog.alert(res.data.list.length)
      if (res.code !== 200)
        return this.$toast.fail("Error: getApplicationByIdFn ");
      // console.log(res)
      // 保存响应的数据到相应的data中
      this.applyId = res.data.applyId;
      this.applyCourse = res.data.applyCourse;
      // 根据applyCourse判断运作部是否同意， applyCourse=4或6为同意， applyCourse=5表示不同意
      if(this.applyCourse == 4 || this.applyCourse == 6){
        this.agreeRadio = '2';
      }else if (this.applyCourse == 5){
        this.agreeRadio = '1';
      }else{
        this.agreeRadio = '0';
      }
      this.username = res.data.applyName;
      this.calendarValue = res.data.applyTime;
      this.useraccount = res.data.applyAccount;
      this.scaleValue = parseInt(res.data.applyType);
      this.reason = res.data.applyReason;
      this.applyCourse = res.data.applyCourse;
      this.setScaleText(this.scaleValue); // 设置scaleText
      this.refuseReasonText = res.data.applyRefuseReason; // 拒绝理由
      // this.operationId = res.data.applyMechanism; // 机构负责人的userid
      // this.riskDepUserId = res.data.jsonObject.user;
      this.riskDepUserId = res.data.loginUser;

      // 接口请求回来的提交按钮状态, 如果请求返回的btn是false，禁用提交按钮
      this.btnStatus = res.data.btn;
      if (this.btnStatus == false) {
        this.disableFlg = true;
        this.radioRiskDisabled = true; // 运作部审批同意/不同意radio禁用
      }

      // 获取发起人所在的部门
      for (var i = 0; i < res.data.list.length; i++) {
        console.log(res.data.list[i]);
        this.columnsDept.push(res.data.list[i]);
      }

      // 获取本申请的所属市场机构, 并赋值
      for (var i = 0; i < res.data.list.length; i++) {
        if (res.data.applyUserDept == res.data.list[i].id) {
          this.pickerOperationDept = res.data.list[i].name;
        }
      }

      // 机构负责人姓名列表  和 机构负责人姓名id列表
      // this.columnsOperationIds = res.data.jsonObject.userlist;
      // for (var i = 0; i < res.data.jsonObject.userlist.length; i++) {
      //   this.columnsOperation[i] = res.data.jsonObject.userlist[i].name;
      //   if (this.operationId == res.data.jsonObject.userlist[i].userid) {
      //     this.pickerOperationValue = res.data.jsonObject.userlist[i].name;
      //   }
      // }

      // 保存applyId 和 applyCourse 到vuex中
      // 注意：applyCourse=4表示运作部审批同意；applyCourse=5表示运作部审批不同意
      // 如果运作部审批不同意，保存refuseReasonText 到vuex中
      this.$store.commit("setApplyId", this.applyId);
      this.$store.commit("setApplyCourse", this.applyCourse);
      if (this.applyCourse === 5) {
        this.agreeRadio = "1"; // 选择不同意 checked
        this.$store.commit("setRefuseReasonText", this.refuseReasonText);
      }

      // 保存签名数据
      function getSignFn(signList) {
        return signList.signType === "客户签名";
      }
      function getMarketSignFn(signList) {
        return signList.signType === "客户经理签名";
      }
      function getOperationSignFn(signList) {
        return signList.signType === "机构负责人签名";
      }
      if (res.data.signList != "") {
        // 保存客户签名
        this.sigImg = res.data.signList.find(getSignFn).signImg;
        // 保存客户经理签名
        if (res.data.signList.length > 1) {
          let marketSigImg = res.data.signList.find(getMarketSignFn).signImg;
          if (marketSigImg !== null) {
            this.marketSigImg = marketSigImg;
          }
        }
        // 保存机构负责人签名
        if (res.data.signList.length > 2) {
          let operationSigImg =
            res.data.signList.find(getOperationSignFn).signImg;
          if (operationSigImg !== null) {
            this.operationSigImg = operationSigImg;
          }
        }
      }

      // applyCourse=4 or applyCourse=5 表示运作部审批 同意/不同意, 禁用提交按钮
      if (this.applyCourse === 4 || this.applyCourse === 5) {
        this.disableFlg = true;
        // alert("agree/noagree")
      }
    },
    // 从地址栏中获取code参数值
    getUrlCodeParamFn(param) {
      // console.log("getUrlCodeParamFn(param)-->param=", param);
      // console.log(this.$route.fullPath);
      console.log(window.location.href);
      // 获取id
      if (param == "id") {
        if (window.location.href.indexOf("id") != -1) {
          return window.location.href.split("id")[1].split("=")[1];
        }
        return "";
      } else if (param == "code") {
        // 获取code
        if (window.location.href.indexOf("#") != -1) {
          let url = window.location.href.split("?")[1].split("#")[0];
          // console.log(url);
          let obj = {};
          let arr = url.split("&");
          for (let i = 0; i < arr.length; i++) {
            let arrNew = arr[i].split("=");
            obj[arrNew[0]] = arrNew[1];
          }
          if (obj[param] !== null){
            if(obj[param].indexOf("#") == -1){
              return obj[param];
            }else{
              return obj[param].split("#")[0];
            }

          } 
        }
        return "";
      }
      return "";
    },

    // 企业微信认证后，获取用户id
    async getUserIdFn(code) {
      const res = await getUserIdAPI(code);
      // this.weComCode = this.getUrlCodeParamFn()
      // console.log(res);
      this.weComUserId = res.data.data.user;
      // this.weComDeptUserList = res.data.data.userlist
    },

    // 企业微信认证后，获取用户id
    async getUserIdOne(code) {
      console.log(code);
      const res = await getWxUserID(code);
      console.log(res);
      if (res.code == 200) {
        console.log(res);
        this.weComUserId = res.data;
      } else {
        console.log(res.message);
      }
      // this.weComCode = this.getUrlCodeParamFn()

      // this.weComDeptUserList = res.data.data.userlist
    },

    dialogConfirmFn() {
      this.$dialog
        .confirm({
          title: "申请特殊交易保证金告知书",
          messageAlign: "left",
          message:
            "一、特殊交易保证金（以下简称“特保”）是指：公司根据对客户的资信状况、交易目的、交易方式等方面的评估，对客户采取相应的保证金收取水平。\n" +
            "二、特保按品种设定四个级别的收取标准，对于申请特保的客户，需拥有良好的风险处置配合度，同时满足以下对交易资金量的要求。\n" +
            "1、良好风险配合度是指：\n" +
            "(1)日内交易不留仓，交易方式主要为套保及套利等完全对锁持仓交易；\n" +
            "(2)可主动控制好账户资金使用率及风险度；\n" +
            "(3)基本不存在被公司强平的情况，或偶尔被强平后，能及时确认强平结果；\n" +
            "(4)其他风险可控的特殊情况，如有信用保障的重点服务客户等。\n" +
            "2、资金量标准是指：\n" +
            "(1)交易所标准+3：参与交易的资金量在10万以上；\n" +
            "(2)交易所标准+2：参与交易的资金量在50万以上；\n" +
            "(3)交易所标准+1：参与交易的资金量在200万以上；\n" +
            "(4)交易所标准+0：参与交易的资金量需达到一定规模。\n" +
            "3、特保获批的客户，公司将对其交易、持仓及资金风险等情况进行持续关注。若客户未达到事先承诺的标准或风险配合度较差，公司有权取消其特保资格。\n" +
            "4、通常情况下，临近长假公司将统一取消特保优惠，所有客户按公司保证金收取标准执行。\n" +
            "三、备注：\n" +
            "(1)交易所标准+3：五家+3%，中金所股指+2%, 国债+0.3% \n" +
            "(2)交易所标准+2：五家+2%，中金所股指+1%, 国债+0.2% \n" +
            "(3)交易所标准+1：五家+1%，中金所股指+1%, 国债+0.1% \n" +
            "(4)交易所标准+0：六家+0 \n" +
            "(5)五家：上期所、能源中心、大商所、郑商所、广期所 \n" +
            "(6)六家：上期所、能源中心、大商所、郑商所、广期所、中金所 \n" +
            "公司根据市场情况，保留调整比例的权力 \n"
            ,
          // '本人深知期货市场的风险性，并将严格按照贵公司风险控制标准执行，本人认可贵公司有权根据期货交易所、结算机构的规定、市场情况，因本人风险配合度差、资金持续不达标以及贵公司认定其他需要调整保证金比例的情况，本人同意贵公司调整本人期货账户保证金比例，并愿意承担由此造成的一切风险！'
          width: "90%",
          theme: "round",
          confirmButtonColor: "#1989fa",
          showCancelButton: false,
        })
        .then(() => {
          // on confirm
        });
    },
    // 递归部门目录树方法
    recursionTreeFn(list, value) {
      let children = [];
      list.forEach((item) => {
        if (item.parentid == value) {
          // 是当前循环的菜单的子集合，写入数组
          // 然后当前整个item可能还不是最后一级菜单，继续执行方法，找找有没有子集
          children.push(item);
          item.children = this.recursionTreeFn(list, item.value);
        }
      });
      return children;
    },
    // 递归将children为[]的，改为children=null
    recursionNullFn(arr) {
      arr.forEach((item) => {
        if (item.children.length == 0) {
          item.children = null;
        } else {
          this.recursionNullFn(item.children);
        }
      });
    },
    // 获取部门树
    getDeptTreeFn(arr) {
      // 1. 先整理请求回来的部门树数组，形成新的数组deptArr
      let deptArr = [];

      for (var i = 0; i < arr.length; i++) {
        let tmpObj = {};
        // tmpObj.id = arr[i].id
        tmpObj.value = arr[i].id;
        tmpObj.parentid = arr[i].parentid;
        tmpObj.text = arr[i].name;
        deptArr.push(tmpObj);
      }

      // 2. 递归形成部门树
      this.deptOptions = this.recursionTreeFn(deptArr, 661);
      console.log(this.deptOptions);

      // 3. 对children为[], 改为null, 表示结束
      this.recursionNullFn(this.deptOptions);

      // 遍历并解析请求回来的部门树
      // let deptObj = {}
      // for(var i=0;i<arr.length;i++){
      //   deptObj.id = arr[i].id
      //   deptObj.text = arr[i].name
      //   deptObj.parentid = arr[i].parentid
      //   deptObj.children = null
      // }

      // const map = new Map();
      // arr.forEach(item => {
      //   map.set(item.id, item);
      // })
      // // console.log(map);
      // // 将子元素依次放入父元素中
      // const res = []
      // arr.forEach(item => {
      //   const parent = map.get(item.parentid);
      //   if(parent) {
      //     (parent.children || (parent.children = [])).push(item)
      //   }else{
      //     res.push(item)
      //   }
      // })
      // console.log(res);
    },
  },
  mounted() {
    this.getDeptTreeFn(this.deptOptions1);
    // Dialog({message: 'this.disableFlg=' + this.disableFlg})
    // Dialog({message: 'this.btnStatus=' +　this.btnStatus})

    // 解析下deptOptions
    // let someArr = [661, 1, 2, 3]
    // 第一层的
    // let someArr1 = this.deptOptions1.filter(
    //   (item) => item.id == 54 || item.id == 55
    // );
    // // 第二层的
    // let someArr2 = this.deptOptions1.filter(
    //   (item) => item.parentid == 54 || item.parentid == 55
    // );
    // // 第三层和第四层的
    // let someArr3 = this.deptOptions1.filter(
    //   (item) =>
    //     item.id != 54 &&
    //     item.id != 55 &&
    //     item.parentid != 54 &&
    //     item.parentid != 55
    // );
    // // var obj = []
    // // 挑选出一级(上海市场、分支机构)
    // for (var i = 0; i < someArr1.length; i++) {
    //   let tempObj1 = {};
    //   tempObj1.text = someArr1[i].name;
    //   tempObj1.id = someArr1[i].id;
    //   tempObj1.value = "" + (i + 1);
    //   tempObj1.children = [];
    //   // 挑选一级对应的二级机构
    //   for (var j = 0; j < someArr2.length; j++) {
    //     let tempObj2 = {};
    //     if (someArr2[j].parentid == someArr1[i].id) {
    //       tempObj2.text = someArr2[j].name;
    //       tempObj2.id = someArr2[j].id;
    //       tempObj2.value = "0" + (j + 1);
    //       tempObj2.children = [];
    //       for( var k =0; k< someArr3.length; k++){
    //         let tempObj3 = {}
    //         if(someArr3[k].parentid == someArr2[j].id){
    //           tempObj3.text = someArr3[k].name
    //           tempObj3.id = someArr3[k].id
    //           tempObj3.value = "00" + (k+1)
    //           tempObj3.children = null
    //           tempObj2.children.push(tempObj3)
    //         }
    //       }
    //     }
    //     tempObj1.children.push(tempObj2)
    //   }
    //   this.deptOptions.push(tempObj1);
    // }

    // console.log(this.deptOptions);

    // 页面加载时先跳出弹出窗口
    // this.dialogConfirmFn();
    // console.log(process.env.BASE_URL);
    // 获取保证金比例列表
    this.getMarginsListFn();

    let id = this.getUrlCodeParamFn("id");

    // console.log("id=", id);
    if (typeof id == "undefined" || id == "") {
      // console.log('id==undefined');
      this.dialogConfirmFn();

      this.weComCode = this.getUrlCodeParamFn("code");
      if (this.weComCode != "") {
        this.getUserIdOne(this.weComCode);
      } else {
        return Toast.fail("请在企业微信端访问！");
      }
    } else if (id != "") {
      // console.log("id != ''");
      this.weComCode = this.getUrlCodeParamFn("code");
      // console.log(this.weComCode);
      // 根据企业微信链接地址code发请求获取发起人，发起人部门成员列表
      if (this.weComCode != "") {
        this.getApplicationByIdFn(this.weComCode, id);
        // console.log(this.weComCode);
        // console.log(id);
      } else {
        return Toast.fail("请在企业微信端访问！");
      }
    }
  },
  components: {
    Signature,
    // FooterBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Calendar.name]: Calendar,
    [Picker.name]: Picker,
    [Dialog.name]: Dialog,
    [NoticeBar.name]: NoticeBar,
    [ShareSheet.name]: ShareSheet,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Notify.name]: Notify,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [Lazyload.name]: Lazyload,
    [CountDown.name]: CountDown,
    [Image.name]: Image,
    [Cascader.name]: Cascader,
  },
};
</script>

<style lang="less" scoped>
* {
  // touch-action: none;
}

.home-container {
  // padding-bottom: 50px;
  .banner {
    position: fixed;
    top: 0;
    z-index: 1;

    img {
      height: 100px;
    }
  }

  .content {
    margin-top: 90px;
    .van-cell {
      font-size: 12px;
      .van-cell__value {
        border-bottom: 0.02667rem solid #ddd;
        color: #888;
      }
    }
  }
  .van-form {
    padding-bottom: 2.66666rem;
  }

  // .footer {
  //   position: fixed;
  //   .van-notice-bar {
  //     bottom: 1.33333rem;
  //     left: 0;
  //     z-index: 999;
  //   }
  // }
}

.van-notice-bar {
  // bottom: 1.33333rem;
  // left: 0;
}

/deep/ .van-dialog {
  // top: 52% !important
}

.van-popup {
  width: 100%;
  padding: 10px;
}

.van-radio-group {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.marginNoticeText {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 30px 15px 0px 15px;

  h3 {
    text-align: center;
  }
}

// .van-notice-bar__content {
//   z-index: 999;
// }
</style>

