import axios from 'axios'

// export const baseURL = 'http://rgallery.natapp4.cc'
// // export const baseURL = '/api'
// // export const baseURL = 'http://test-cn.your-api-server.com'

// const myAxios = axios.create({
//   baseURL: baseURL
// })



//export const baseURL = 'http://localhost:8081'

export const baseURL = 'http://1.15.138.19'
// export const baseURL = '/api'
// export const baseURL = 'http://test-cn.your-api-server.com'
 export const returnUrl = 'http://wx.zcfutures.com/margins/#'
 
const myAxios = axios.create({
    baseURL: baseURL,
    returnUrl:returnUrl
})
myAxios.interceptors.request.use((config) => {
        return config;
    }),
    error => {
        return Promise.reject(error)
    };
myAxios.interceptors.response.use((res) => {
        return res.data;
    }),
    error => {
        return Promise.reject(error)
}

export default myAxios