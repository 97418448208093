import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    applyId: "",
    applyCourse: 0,
    refuseReasonText: ''
  },
  mutations: {
    setApplyId(state, value){
      state.applyId = value
    },
    setApplyCourse(state, value){
      state.applyCourse = value
    },
    setRefuseReasonText(state, value){
      state.refuseReasonText = value
    }
  }
})

export default store;