<template>
  <div class="home-container">
    <!-- banner区域 -->
    <div class="banner">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" width="100%" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 保证金优惠标准弹出文本区域 -->
    <div class="content">
      <div class="readme">
        <van-cell is-link title="保证金优惠标准"> </van-cell>
        <!-- <van-popup v-model="showStandard">
          <van-cell-group>
            <van-cell title="10万≤期货权益≤50万" value="交易所+3" />
            <van-cell title="50万≤期货权益≤200万" value="交易所+2" />
            <van-cell title="200万≤期货权益" value="交易所+1" />
          </van-cell-group>
        </van-popup> -->
      </div>
    </div>

    <!-- 表单区域 -->
    <van-form>
      <!-- 选择日期 -->
      <van-field readonly label="申请日期" placeholder="选择日期" />
      
      <!-- 客户姓名 -->
      <van-field readonly label="客户姓名" placeholder="请输入客户姓名" />

      <!-- 资金账号 -->
      <van-field readonly label="资金账号" placeholder="请输入资金账号" />

      <!-- 申请保证金优惠对应比例 -->
      <van-field
        readonly
        label="申请比例"
        name="scaleText"
        placeholder="选择申请比例"
      />

      <!-- 申请理由文本 -->
      <van-field
        readonly
        type="textarea"
        label="申请理由"
        placeholder="申请理由"
        rows="4"
        autosize
        input-align="left"
        colon
        maxlength="300"
      />

      <div style="margin: 16px">
        <!-- 提交按钮 -->
        <van-button
          round
          block
          type="info"
          native-type="submit"
          name="submit"
          class="submitBtn"
          >提交</van-button
        >
      </div>
    </van-form>

    <!-- 底部区域 -->
    <!-- <div class="footer"> -->
    <!-- 跑马灯提示区域 -->
    <van-notice-bar
      left-icon="volume-o"
      text="中财期货线上移动端保证金优惠申请......"
      scrollable
    />
    <!-- 固定尾部区域 -->
    <!-- <router-view></router-view>
    <FooterBar></FooterBar> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { getCodeAPI } from "@/api/index.js";
// import { Swipe } from 'vant';
import {
  Checkbox,
  Swipe,
  SwipeItem,
  Popup,
  Button,
  Cell,
  CellGroup,
  Row,
  Col,
  Form,
  Field,
  Calendar,
  Picker,
  Dialog,
  NoticeBar,
  Toast,
  ShareSheet,
  Step,
  Steps,
  Notify,
  RadioGroup,
  Radio,
  ContactEdit,
} from "vant";
export default {
  name: "Test",
  data() {
    return {
      images: ["https://s1.ax1x.com/2023/02/20/pSODfyQ.png"],
    };
  },
  methods: {
    async getCodeFn(id) {
      const res = await getCodeAPI(id);
      self.location.href = res;
    },
    // 从地址栏中获取code参数值
    getUrlCodeParamFn(param) {
      if (window.location.href.indexOf("?") != -1) {
        if (window.location.href.indexOf("#") != -1) {
          let url = window.location.href.split("?")[1].split("#")[0];
          // console.log(url);
          let obj = {};
          let arr = url.split("&");
          for (let i = 0; i < arr.length; i++) {
            let arrNew = arr[i].split("=");
            obj[arrNew[0]] = arrNew[1];
          }
          if (obj[param] !== null) return obj[param];
          return ""
        }else {
          let url = window.location.href.split("?")[1];
          // console.log(url);
          let obj = {};
          let arr = url.split("&");
          for (let i = 0; i < arr.length; i++) {
            let arrNew = arr[i].split("=");
            obj[arrNew[0]] = arrNew[1];
          }
          if (obj[param] !== null) return obj[param];
          return ""
        }
      }
      return "";
    },

    // getUrlCodeParamFn(param) {
    //   // console.log('Test --> ');
    //   // console.log('Test组件: getUrlCodeParamFn(param) --> param=', param);
    //   // console.log(this.$route.fullPath);
    //   if (this.$route.fullPath.indexOf('?') != -1) {
    //     let url = this.$route.fullPath.split("?")[1];
    //     let obj = {};
    //     let arr = url.split("&");
    //     for (let i = 0; i < arr.length; i++) {
    //       let arrNew = arr[i].split("=");
    //       obj[arrNew[0]] = arrNew[1];
    //     }
    //     // console.log(obj["code"]);
    //     if (obj[param] !== null) return obj[param];
    //   }
    //   return "";
    // },
  },
  components: {
    // Signature,
    // FooterBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Calendar.name]: Calendar,
    [Picker.name]: Picker,
    [Dialog.name]: Dialog,
    [NoticeBar.name]: NoticeBar,
    [ShareSheet.name]: ShareSheet,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Notify.name]: Notify,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
  },
  // 页面加载时 -> 请求保证金优惠比例列表,并保存;
  mounted() {
    // console.log("Test");
    // let code = this.getUrlCodeParamFn("code")
    let id = this.getUrlCodeParamFn("id");
    // console.log("id=", id);
    this.getCodeFn(id);
  },
};
</script>

<style lang="less" scoped>
* {
  // touch-action: none;
}

.home-container {
  // padding-bottom: 50px;
  .banner {
    position: fixed;
    top: 0;
    z-index: 1;

    img {
      height: 100px;
    }
  }

  .content {
    margin-top: 100px;
  }

  .van-form {
    padding-bottom: 2.66666rem;
  }

  // .footer {
  //   position: fixed;
  //   .van-notice-bar {
  //     bottom: 1.33333rem;
  //     left: 0;
  //     z-index: 999;
  //   }
  // }
}

.van-notice-bar {
  bottom: 1.33333rem;
  left: 0;
}

.van-popup {
  width: 100%;
  padding: 10px;
}

.van-radio-group {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.marginNoticeText {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 30px 15px 0px 15px;

  h3 {
    text-align: center;
  }
}
</style>

