<template>
  <div class="progress-container">
    <van-steps :active="active" active-color="#38f" direction="vertical">
      <van-step>发起申请</van-step>
      <van-step>客户确认</van-step>
      <van-step>查阅审核(客户经理)</van-step>
      <van-step>查阅审核(机构负责人)</van-step>
      <van-step>查阅审核(运作管理部)</van-step>
      <van-step v-show="agree === 1 && active === 5">审核通过</van-step>
      <van-step v-show="agree === 2 && active === 6">审核未通过</van-step>
    </van-steps>
    <van-field v-show="agree === 2 && active === 6" v-model="$store.state.refuseReasonText" name="refuseReason" type="textarea" label="拒绝原因" readonly rows=4 autosize input-align="left" maxlength="300" />

    <!-- <router-view></router-view> -->
    <!-- <FooterBar></FooterBar> -->
  </div>
</template>

<script>
// import FooterBar from 'components/FooterBar.vue'
import { Step, Steps, Field } from 'vant'

export default {
  data() {
    return {
      active: 0, // 当前的步骤
      agree: 0, // 0:没有到审批是否通过; 1: 审批通过; 2: 审批未通过
      refuseReason: '' // 不通过的原因
    }
  },
  computed: {
    // active() {
    //   if (this.$store.state.applyId === '' && this.$store.state.applyCourse === 0) return 0
    //   else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 0) return 1
    //   else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 1) return 2
    //   else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 2) return 3
    //   else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 3) return 4
    //   return 5
    // }
  },
  mounted() {
    if (this.$store.state.applyId === '' && this.$store.state.applyCourse === 0) this.active = 0
    else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 0) this.active = 1
    else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 1) this.active = 2
    else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 2) this.active = 3
    else if (this.$store.state.applyId !== '' && this.$store.state.applyCourse === 3) this.active = 4
    else if (this.$store.state.applyCourse === 4 || this.$store.state.applyCourse === 6) {
      this.agree = 1
      this.active = 5
    } else {
      this.agree = 2
      this.active = 6
    }
  },
  components: {
    // FooterBar,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Field.name]: Field
  }
}
</script>

<style lang="less" scoped>
.progress-container {
  padding-top: 20px;
}
</style>