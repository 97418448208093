import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from 'views/Home.vue';
import Test from 'views/Test.vue';
import ClientHome from 'views/ClientHome.vue';

import Progress from 'views/Progress.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Test',
    component: Test
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/margins',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/clienthome',
    name: 'ClientHome',
    component: ClientHome
  },
  {
    path: '/progress',
    name: 'progress',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // 懒加载组件
    // component: () => import(/* webpackChunkName: "about" */ 'views/Progress.vue')
    component: Progress
  },
  {
    path: '*',
    name: '404',
    component: () => import( /* webpackChunkName: "about" */ 'views/404.vue')
  }
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  // base:'./',
  routes
});

export default router;